import React, { useState, useEffect } from 'react';
import {
  SimpleGrid,
  Tooltip,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  GridItem,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  InputRightAddon,
} from '@chakra-ui/react';
import { useUserStore } from 'store/reducers/user';
import { FaSearch, FaIdCard, FaFileDownload, FaCheck, FaTimes, FaThumbsUp, FaPaperPlane, FaComment, FaEnvelope, FaFlag, FaConnectdevelop, FaCloudDownloadAlt, FaChild, FaEdit } from 'react-icons/fa';
import "./UserReports.css";
import $ from "jquery";
import "jquery-mask-plugin";
import { adminApi } from 'services/AdminApi/api';
import { getSellers, GetSellers } from "services/AdminApi/register";
import { LabelCheckbox } from "./styles";
import { Select, Stack } from '@chakra-ui/react'
import {} from "services/AdminApi/";
import { createRecipientOnGatewayProvider } from 'services/AdminApi/payments';
import ProviderAccountBox from 'views/admin/acessos/components/ProviderAccountBox';
import { useQuery } from '@tanstack/react-query';
import { getUserPaymentTypes, getUserProviderAccounts } from 'services/PaymentsApi/providerAccounts';
import LoadingPage from 'components/loading/LoadingPage';
import { notifyError, notifySuccess } from 'utils/notification';
import { updateDescriptor } from 'services/PaymentsApi/sellers';
import { ProviderKey } from 'models/ProviderAccount';

export default function UserReports() {

  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserDescriptor, setSelectedUserDescriptor] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState(false);
  const [providerKey, setProviderKey] = useState<ProviderKey | "">("");
  const [isLoading, setIsLoading] = useState(false);
  const auth = useUserStore((state) => state.auth);
  const [isDocsModalOpen, setIsDocsModalOpen] = useState(false);
  const [description, setDescription] = useState('');

  const [segundos, setSegundos] = useState(0);
  const [minutos, setMinutos] = useState(0);

  const { data: providerAccounts, isLoading: isLoadingProviderAccounts, refetch } = useQuery(
    ["userProviderAccounts", selectedUser?.id],
    () => getUserProviderAccounts(selectedUser?.id),
    {
      enabled: !!selectedUser && isAnalysisModalOpen
    }
  );

  const { data: sellerPaymentTypes, isLoading: isLoadingSellerPaymentTypes, refetch: refetchSellerPaymentTypes } = useQuery(
    ["sellerPaymentTypes", selectedUser?.id],
    () => getUserPaymentTypes(selectedUser?.id),
    {
      enabled: !!selectedUser && isAnalysisModalOpen
    }
  );

  const [searchQueries, setSearchQueries] = useState({
    name: '',
    email: '',
    cellphone: '',
    mail_date_code: '',
    cnpj_razao: '',
    analise: '',
    mail_active: false,
    activated: false
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;


  useEffect(() => {
    const interval = setInterval(() => {
      // Atualiza os segundos a cada segundo
      setSegundos(segundos => segundos + 1);
    }, 1000);

    return () => {
      // Limpa o intervalo quando o componente é desmontado
      clearInterval(interval);
    };
  }, []);

  const searchSellersRequest = async (params: GetSellers.Params, clearSearchQueries?: boolean) => {

    try {
      const result = await getSellers(params);

      if (!result.success) console.log('toastify de erro');

      const data = result.data

      setUserData(data);
      setCurrentPage(1);
      if (clearSearchQueries) {
        setSearchQueries({
          name: '',
          email: '',
          cellphone: '',
          mail_date_code: '',
          cnpj_razao: '',
          analise: '',
          mail_active: false,
          activated: false
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSegundos(0);
      setMinutos(0);

      setIsLoading(false);
    }
  }
  const handleAtualiza = () => {
    searchSellersRequest({}, true)
  };

  const handleNovosUsers = async () => {
    searchSellersRequest({ analise: ['Pedir'] }, false);
    setSelectedCard('novosUsers');
  };
  
  const handleAprovados = async () => {
    searchSellersRequest({
      analise: ['Aprovado'],
      mail_active: true,
      activated: true
    }, false);
    setSelectedCard('aprovados');
  };
  
  const handleRejeitados = async () => {
    searchSellersRequest({
      analise: ['Rejeitado'],
      mail_active: true,
    }, false);
    setSelectedCard('rejeitados');
  };

  const handleAtivando = async () => {
    searchSellersRequest({
      mail_active: true,
      activated: false
    }, false);
  };

  const handleAnalise = async () => {
    searchSellersRequest({
      analise: ['Aguardando'],
      mail_active: true,
      activated: true
    }, false);
    setSelectedCard('analise');
  };
  
  const handleCorrigir = async () => {
    searchSellersRequest({
      analise: ['Corrigir'],
      mail_active: true,
    }, false);
    setSelectedCard('corrigir');
  };

  useEffect(() => {
    // Atualiza os minutos quando os segundos chegam a 60
    if (segundos === 60) {
      setSegundos(0);
      setMinutos(minutos => minutos + 1);
    }
  }, [segundos]);

  const fetchUserData: any = async (isSearch = false) => {
    let request: any = {
      name: searchQueries.name,
      email: searchQueries.email,
      cellphone: searchQueries.cellphone,
      mail_date_code: searchQueries.mail_date_code,
      cnpj_razao: searchQueries.cnpj_razao,
    };

    if (!isSearch) {
      request.analise = searchQueries.analise
      request.mail_active = searchQueries.mail_active
      request.activated = searchQueries.activated
    }

    searchSellersRequest(request, false);
  };



  useEffect(() => {
    fetchUserDataNovos();
    const interval = setInterval(fetchUserDataNovos, 5000);
    return () => clearInterval(interval);
  }, []);





  useEffect(() => {
    fetchUserData();
    fetchUserDataNovos();
  }, []);




  const [novosUsers, setNovosUsers] = useState(0);
  const [emailativado, setEmailAtivado] = useState(0);
  const [analiseNovo, setAnaliseNovo] = useState(0);
  const [aprovadoNovo, setAprovadoNovo] = useState(0);
  const [rejeitadoNovo, setRejeitadoNovo] = useState(0);
  const[corrigirNovo, setCorrigirNovo] = useState(0);

  const fetchUserDataNovos = async () => {
    const formData = new FormData();
    formData.append('time', 'time');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/all`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        body: formData
      });
      
      const data = await response.json();

      let i = 0;
      data.forEach((item: any) => {
        if (item['analise'] === 'Pedir') {
          i++;
        }
      });
      setNovosUsers(i)

      i = 0;
      data.forEach((item: any) => {
        if ((item['mail_active'] === true) && item['analise'] === 'Corrigir') {
          i++;
        }
      });
      setCorrigirNovo(i)

      i = 0;
      data.forEach((item: any) => {
        if ((item['mail_active'] === true) && (item['activated'] === false)) {
          i++;
        }
      });
      setEmailAtivado(i)
      i = 0;
      data.forEach((item: any) => {
        if ((item['mail_active'] === true) && (item['activated'] === true) && (item['analise'] === 'Aguardando')) {
          i++;
        }
      });
      setAnaliseNovo(i)
      i = 0;
      data.forEach((item: any) => {
        if ((item['mail_active'] === true) && (item['activated'] === true) && (item['analise'] === 'Aprovado')) {
          i++;
        }
      });
      setAprovadoNovo(i)

      i = 0;
      data.forEach((item: any) => {
        if ((item['mail_active'] === true) && (item['analise'] === 'Rejeitado')) {
          i++;
        }

      });
      setRejeitadoNovo(i)

    } catch (error) {

    } finally {

    }
  };







  const handleSearchChange = (event: any, column: any) => {
    const { value } = event.target;
    setSearchQueries((prevState) => ({
      ...prevState,
      [column]: value
    }));
  };

  const handleSearchSubmit = (event: any) => {
    event.preventDefault();
    fetchUserData(true);
  };


  const handleEditButtonClick = (user: any) => {
    setSelectedUser(user);
    fetchNegotiatedTaxRate(user);
    setIsModalOpen(true);
  };

  const handleDocumentModalOpen = (user: any) => {
    setSelectedUser(user);
    setIsDocumentModalOpen(true);
  };

  const handleAnalysisModalOpen = (user: any) => {
    setSelectedUser(user);
    setIsAnalysisModalOpen(true);
    setSelectedUserDescriptor(user?.seller?.descriptor)
  };

  const renderPreloader = () => {
    return isLoading ? (
      <div className="preloader-overlay">
        <div className="preloader-content">
          <div className="preloader-spinner"></div>
        </div>
      </div>
    ) : null;
  };




  const handleAnalysisSubmit = async () => {
    if (selectedUserDescriptor !== selectedUser?.seller?.descriptor) {
      notifyError('Salve o Software Descriptor antes de enviar para análise!');
      return
    }

    try {
      setIsLoading(true);

      if (providerKey != "" && Object.values(ProviderKey).includes(providerKey)) {
        const response = await createRecipientOnGatewayProvider({id: selectedUser?.id, provider_key: providerKey})

        const data = response.data;

        setSelectedUser({ ...selectedUser, stone_status: data.status, stone_recipe_id: data.stone_recipe_id });
        refetch();
        refetchSellerPaymentTypes();
      }


    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);

    }
  };

  const handleDescriptorUpdate = async () => {
    try {
      setIsLoading(true);

      await updateDescriptor(selectedUser?.id, selectedUserDescriptor)

      setSelectedUser({ ...selectedUser, seller: { ...selectedUser?.seller, descriptor: selectedUserDescriptor } })

      notifySuccess('Descriptor atualizado com sucesso!');
    } catch (e) {
      console.log(e);
      notifyError('Erro ao atualizar descriptor!');
    } finally {
      setIsLoading(false);
    }
  }

  const handleApprove = async () => {
    try {
      setIsLoading(true);
      // Lógica para aprovar o vendedor (chamada da API)
      const formData = new FormData();

      // Append form data
      formData.append("id", selectedUser.id + "");


      // Send form data to the API
      const response = await adminApi.post(
        `${process.env.REACT_APP_API_URL}/api/admin/seller_apr`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`
          }
        }
      );

      const updatedUser = await response.data;

      // Atualizar o estado do vendedor com o status aprovado
      $('#apr' + selectedUser.id).html(' Aprovado ');

    } catch (error) {
      console.log(error);
    } finally {


      setIsLoading(false);
      setIsAnalysisModalOpen(false);
    }
  };

  const handleReject = async () => {
    try {
      setIsLoading(true);
      // Lógica para rejeitar o vendedor (chamada da API)
      const formData = new FormData();

      // Append form data
      formData.append("id", selectedUser.id + "");


      // Send form data to the API
      const response = await adminApi.post(
        `${process.env.REACT_APP_API_URL}/api/admin/seller_rjct`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`
          }
        }
      );

      const updatedUser = await response.data;

      // Atualizar o estado do vendedor com o status rejeitado
      $('#apr' + selectedUser.id).html(' Rejeitado ');

    } catch (error) {
      console.log(error);
    } finally {


      setIsLoading(false);
      setIsAnalysisModalOpen(false);
    }
  };

  const handleActivatedSelect = (user: any) => {
    handleActivated(user);
  };

  const handleResendMail = (user: any) => {
    handleResend(user);
  };

  const handleActivated = async (user: any) => {
    setIsLoading(true);
    const formData = new FormData();

    // Append form data
    formData.append("id", user + "");

    try {
      // Send form data to the API
      const response = await adminApi.post(
        `${process.env.REACT_APP_API_URL}/api/seller_act`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`
          }
        }
      );

      const updatedUser = await response.data;

      // Atualizar o estado do vendedor com o status retornado pela API
      $('#btn' + user).html(' Ativado ');
      $('#btn' + user).prop('disabled', true);

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }

  };

  const handleResend = async (user: any) => {
    setIsLoading(true);
    const formData = new FormData();

    // Append form data
    formData.append("id", user + "");

    try {
      // Send form data to the API
      const response = await adminApi.post(
        `${process.env.REACT_APP_API_URL}/api/mail_admin_resend`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`
          }
        }
      );

      const updatedUser = await response.data;

      // Atualizar o estado do vendedor com o status retornado pela API
      $('#Res' + user).html('Enviado');


    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }

  };
  function zipcode_alter(n: any) {
    let resultado = '';
    let parte1 = '';
    let parte2 = '';
    let numero = '';
    let posicao = 5;
    numero = n + "";
    numero = numero.replace(/\D/g, '')
    if (numero != '') {
      parte1 = numero.slice(0, posicao);
      parte2 = numero.slice(posicao);
      resultado = parte1 + "-" + parte2;
    }
    return resultado;
  }

  function datetime(n: any) {
    if (typeof n === "string") {
      const parts = n.split("-");
      if (parts.length === 3) {
        const day = parts[2];
        const month = parts[1];
        const year = parts[0];
        return day + "/" + month + "/" + year;
      }
    }
    return n;
  }

  function converterData(n: any) {
    if (n !== '' && n !== null) {
      const partes = n.split(" ")[0].split("-");
      const ano = partes[0];
      const mes = partes[1];
      const dia = partes[2];

      return `${dia}/${mes}/${ano}`;
    } else {
      return '';
    }
  }


  function fone(n: any) {
    if (typeof n === "string" && n.length === 11) {
      const ddd = n.substring(0, 2);
      const parte1 = n.substring(2, 3);
      const parte2 = n.substring(3, 7);
      const parte3 = n.substring(7);
      return `(${ddd}) ${parte1} ${parte2}-${parte3}`;
    }
    return n;
  }

  function cpf(n: any) {
    if (typeof n === "string" && n.length === 11) {
      const parte1 = n.substring(0, 3);
      const parte2 = n.substring(3, 6);
      const parte3 = n.substring(6, 9);
      const parte4 = n.substring(9);
      return `${parte1}.${parte2}.${parte3}-${parte4}`;
    }
    return n;
  }

  const [dataselectb, setDataselectb] = useState([]);

  useEffect(() => {
    fetchDatabanco();
  }, []);

  const handleChangeProviderKey = (event: any) => {
    const selectedValue = event.target.value;
    setProviderKey(selectedValue);

  }
  const fetchDatabanco = async () => {
    try {
      const responseB = await adminApi.get(
        "https://jsonpdataproxy.appspot.com/?url=https%3A%2F%2Fwww.bcb.gov.br%2Fpom%2Fspb%2Festatistica%2Fport%2FParticipantesSTRport.csv&max-results=1000&type=CSV&format=json"
      );

      const parsedDataB = responseB.data.data;

      setDataselectb(parsedDataB);
    } catch (error) {
      // Handle error
    }
  };

  const banco = (n: any) => {
    const bancoEncontrado = dataselectb.find((item) => item[2] === n);
    return bancoEncontrado ? bancoEncontrado[1] : null;
  };


  const handleDocsModalOpen = (user: any) => {
    setSelectedUser(user);

    setIsDocsModalOpen(true);
  };



  const [selectedItems, setSelectedItems] = useState([]);
  
  const [currentNegotiatedTaxRate, setCurrentNegotiatedTaxRate] = useState(''); // Estado para armazenar a taxa negociada atual
  const [currentWarrantyNegotiatedTaxRate, setCurrentWarrantyNegotiatedTaxRate] = useState('');

  const fetchNegotiatedTaxRate = async (user: any) => {
    try {
      const formData = new FormData();
      formData.append('id', user.id);
      const sellerDataResponse = await adminApi.post(
        `${process.env.REACT_APP_API_URL}/api/admin/get-seller-data`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth.token}`,
          }
        }
      );
      setCurrentNegotiatedTaxRate(sellerDataResponse.data[0].negotiated_tax_rate);
      setCurrentWarrantyNegotiatedTaxRate(sellerDataResponse.data[0].negotiated_warranty_tax);
    } catch (error) {
      setCurrentNegotiatedTaxRate("Erro ao buscar taxa");
      setCurrentWarrantyNegotiatedTaxRate("Erro ao buscar taxa");
      console.log(error);
    }
  };
  

  const [negotiatedTaxRate, setNegotiatedTaxRate] = useState("");
  const handleNegotiatedTaxUpdate = async (user: any) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedUser?.id);
      formData.append('negotiated_tax_rate', negotiatedTaxRate);

      const response = await adminApi.post(
        `${process.env.REACT_APP_API_URL}/api/admin/update-seller-tax`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      fetchNegotiatedTaxRate(selectedUser);
      fetchUserData();
    } catch (error) {
      console.log(error);
    }
  };

  const [negotiatedWarrantyTaxRate, setNegotiatedWarrantyTaxRate] = useState("");
  const handleNegotiatedWarrantyTaxUpdate = async (user: any) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedUser?.id);
      formData.append('negotiated_warranty_tax', negotiatedWarrantyTaxRate);

      const response = await adminApi.post(
        `${process.env.REACT_APP_API_URL}/api/admin/update-seller-warranty-tax`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      fetchNegotiatedTaxRate(selectedUser);

      fetchUserData();
    } catch (error) {
      console.log(error);
    }
  };


  const handleDocs = async () => {
    setIsLoading(true);
    const formData = new FormData();

    // Append form data
    formData.append("id", selectedUser?.id);
    formData.append("descricao", description);
    formData.append("itens", JSON.stringify(selectedItems));
    try {
      console.log({
        id: selectedUser?.id,
        descricao: description,
        selected: JSON.stringify(selectedItems)
      })
      // Send form data to the API
      const response = await adminApi.post(
        `${process.env.REACT_APP_API_URL}/api/admin/seller_docs`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`
          }
        }
      );

      const updatedUser = await response.data;



      // Atualizar o estado do vendedor com o status retornado pela API
      fetchUserData();
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setDescription('')
    }
  };

  type RelacionamentoItem = { data: string, descricao: string };
  type Relacionamento = RelacionamentoItem[];

  const relacionamentoString = selectedUser && selectedUser.relacionamento;
  const relacionamento: Relacionamento = relacionamentoString
    ? JSON.parse(relacionamentoString)
    : [];


  const [checkboxItems, setCheckboxItems] = useState([
    'Nome',
    'Email',
    'CPF',
    'Data de Nascimento',
    'CEP',
    'Endereço',
    'Cidade',
    'Estado',
    'Complemento',
    'Número',
    'Documento Frente',
    'Documento Verso',
    'Selfie com Documento',
    'Faturamento',
    'Telefone',
    'Banco ID',
    'Agência',
    'Conta',
    'Número PIX',
    'CNPJ',
    'CNPJ Razão social',
    'Nome Fantasia',
    'CNPJ CEP',
    'CNPJ Endereço',
    'CNPJ Cidade',
    'CNPJ Estado',
    'CNPJ Complemento',
    'CNPJ Número',
    'CNPJ Banco ID',
    'CNPJ Agência',
    'CNPJ Conta',
    'CNPJ Número PIX',
    'CNPJ Documentos',
  ]);



  const handleItemChange = (item: any) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };


  // Função para exibir os dados de acordo com a página atual
  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return userData.slice(startIndex, endIndex);
  };

  // Função para alterar a página atual
  const changePage = (page: any) => {
    setCurrentPage(page);
  };

  // Função para ir para a próxima página
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Função para ir para a página anterior
  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const totalPages = Math.ceil(userData.length / itemsPerPage)



  $(() => {
    $("#cellphonefind").mask("(00) 0 0000-0000");

  });

  function isOver18(birthDate: any) {

    const today = new Date();
    const birth = new Date(birthDate);
    const age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    const dayDiff = today.getDate() - birth.getDate();

    if (age > 18) {
      return true;
    } else if (age === 18 && monthDiff > 0) {
      return true;
    } else if (age === 18 && monthDiff === 0 && dayDiff >= 0) {
      return true;
    }

    return false;

  }

  const antigoCheckboxCondition = selectedUser?.antigo && selectedUser?.antigo[0]
  const [selectedCard, setSelectedCard] = useState(null);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {renderPreloader()}
      <Box overflow={"auto"} maxHeight={"85vh"} minHeight={"85vh"}>
      <div className="cards-container" style={{ display: 'flex', justifyContent: 'center', gap: '2rem', flexWrap: 'wrap', marginBottom: '0rem', marginTop:'1rem' }}>
        <Box
          className={`card ${selectedCard === 'novosUsers' ? 'selected' : ''}`}
          onClick={handleNovosUsers}
          style={{
            backgroundColor: 'gold',
            color: 'black',
            cursor: 'pointer',
            width: '16rem',
            height: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.3s ease',
          }}
        >
          <div className="flag-icon">
            <FaFlag size={25} color="white" />
          </div>
          <div className="text-container">
            <h2 className="title">Novos Usuários</h2>
            <b className="number">{novosUsers}</b>
          </div>
        </Box>

        <Box
          className={`card ${selectedCard === 'analise' ? 'selected' : ''}`}
          onClick={handleAnalise}
          style={{
            backgroundColor: 'orange',
            color: 'black',
            cursor: 'pointer',
            width: '16rem',
            height: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.3s ease',
          }}
        >
          <div className="flag-icon">
            <FaSearch size={25} color="black" />
          </div>
          <div className="text-container">
            <h2 className="title">Para Análise</h2>
            <b className="number">{analiseNovo}</b>
          </div>
        </Box>

        <Box
          className={`card ${selectedCard === 'corrigir' ? 'selected' : ''}`}
          onClick={handleCorrigir}
          style={{
            backgroundColor: 'white',
            color: 'black',
            cursor: 'pointer',
            width: '16rem',
            height: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.3s ease',
          }}
        >
          <div className="flag-icon">
            <FaEdit size={25} color="black" />
          </div>
          <div className="text-container">
            <h2 className="title">Corrigir</h2>
            <b className="number">{corrigirNovo}</b>
          </div>
        </Box>

        <Box
          className={`card ${selectedCard === 'aprovados' ? 'selected' : ''}`}
          onClick={handleAprovados}
          style={{
            backgroundColor: 'LimeGreen',
            color: 'black',
            cursor: 'pointer',
            width: '16rem',
            height: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.3s ease',
          }}
        >
          <div className="flag-icon">
            <FaCheck size={25} color="white" />
          </div>
          <div className="text-container">
            <h2 className="title">Aprovados</h2>
            <b className="number">{aprovadoNovo}</b>
          </div>
        </Box>

        <Box
          className={`card ${selectedCard === 'rejeitados' ? 'selected' : ''}`}
          onClick={handleRejeitados}
          style={{
            backgroundColor: 'crimson',
            color: 'black',
            cursor: 'pointer',
            width: '16rem',
            height: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.3s ease',
          }}
        >
          <div className="flag-icon">
            <FaTimes size={25} color="black" />
          </div>
          <div className="text-container">
            <h2 className="title">Rejeitados</h2>
            <b className="number">{rejeitadoNovo}</b>
          </div>
        </Box>
</div>

        {/* <TableData
        searchSellersRequest={searchSellersRequest}
        paginatedData={getPaginatedData()}/> */}
        <Table variant="simple" borderWidth="1px" borderColor="gray.600" bg="white" fontSize="12px">
          <Thead>
            <Tr bg="gray.200">
              <Th fontSize="small" textAlign="center" px={1} py={1}>Name</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>Email</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>Telefone</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>Data cadastro</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>Razão Social</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>Email Ativado</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>Data Solicitado</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>Conta</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>Ações</Th>
              <Th fontSize="small" textAlign="center" px={1} py={1}>
                <Button onClick={handleSearchSubmit} leftIcon={<FaSearch />} colorScheme="blue">
                  Buscar
                </Button>
              </Th>
            </Tr>
            <Tr bg="gray.200">

              <Th px={1} py={1}>
                <Input
                  bg={'white'}
                  placeholder="Pesquisar nome"
                  fontSize="small"
                  value={searchQueries.name}
                  onChange={(e) => handleSearchChange(e, 'name')}
                />
              </Th>

              <Th px={1} py={1}>
                <Input
                  bg={'white'}
                  placeholder="Pesquisar email"
                  fontSize="small"
                  value={searchQueries.email}
                  onChange={(e) => handleSearchChange(e, 'email')}
                />
              </Th>

              <Th px={1} py={1}>
                <Input
                  id='cellphonefind'
                  bg={'white'}
                  placeholder="Pesquisar telefone"
                  fontSize="small"
                  value={searchQueries.cellphone}
                  onChange={(e) => handleSearchChange(e, 'cellphone')}
                />
              </Th>

              <Th px={1} py={1}>

              </Th>

              <Th px={1} py={1}>
                <Input
                  bg={'white'}
                  placeholder="Pesquisar CNPJ Razão"
                  fontSize="small"
                  value={searchQueries.cnpj_razao}
                  onChange={(e) => handleSearchChange(e, 'cnpj_razao')}
                />
              </Th>
              <Th></Th>
              <Th></Th>
              <Th></Th>
              <Th></Th>
              <Th px={1} py={1}>

              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {getPaginatedData().map((user) => {
              const relacionamentoArray = user.relacionamento ? JSON.parse(user.relacionamento) : [];
              const relacionamentoLength = relacionamentoArray.length;
              return (


                <Tr key={user.id} _hover={{ bg: !isOver18(user.birth_date) ? 'Yellow' : 'gray.200' }}>
                  <Td style={{ whiteSpace: 'nowrap' }} px={1} py={1}>
                    {!isOver18(user.birth_date) ? (
                      <>
                        <Button
                          colorScheme="red"
                          leftIcon={<FaChild color='black' />}
                          size="sm"
                          variant="outline"
                        >
                          {user.name}
                        </Button>
                      </>
                    ) : user.name}
                  </Td>
                  <Td style={{ whiteSpace: 'nowrap' }} px={1} py={1}>{user.email}</Td>
                  <Td style={{ whiteSpace: 'nowrap' }} textAlign="center" px={1} py={1}>{fone(user.cellphone)}</Td>
                  <Td style={{ whiteSpace: 'nowrap' }} textAlign="center" px={1} py={1}>{converterData(user.mail_date_code)}</Td>
                  <Td px={1} py={1}>{user.cnpj_razao}<br />{user.cnpj}</Td>
                  <Td style={{ whiteSpace: 'nowrap' }} px={1} py={1}>{user.mail_active ? (
                    <>
                      <Button
                        colorScheme="green"
                        leftIcon={<FaCheck color='green' />}
                        size="sm"
                        variant="outline"
                        isDisabled={true}
                      >
                        Verificado
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        id={"Res" + user.id}
                        onClick={() => handleResendMail(user.id)}
                        colorScheme="red"
                        leftIcon={<FaTimes color='red' />}
                        size="sm"
                        variant="outline"
                        isDisabled={user.mail_active ? true : false}
                      >
                        Reenviar email?
                      </Button>
                    </>
                  )}

                  </Td>
                  <Td style={{ whiteSpace: 'nowrap' }} textAlign="center" px={1} py={1}>{converterData(user.data_solicitado)}</Td>
                  <Td style={{ whiteSpace: 'nowrap' }} px={1} py={1}>
                    {user.mail_active ? (
                      <>
                        <Button
                          id={"btn" + user.id}
                          onClick={() => handleActivatedSelect(user.id)}
                          colorScheme="green"
                          size="sm"
                          variant="outline"
                          isDisabled={user.activated ? true : false}
                        >
                          {user.activated ? " Ativado " : " Ativar "}
                        </Button>
                      </>
                    ) : (
                      <label>Não ativou email!</label>
                    )}
                  </Td>

                  <Td style={{ whiteSpace: 'nowrap' }} px={1} py={1}>
  {user.analise !== "Pedir" ? (
    <>
      <Button
        id={"apr" + user.id}
        onClick={() => handleAnalysisModalOpen(user)}
        colorScheme={user.analise === "Rejeitado" ? "red" : user.analise === "Aprovado" ? "green" : "yellow"}
        size="sm"
        variant="outline"
      >
        {user.analise}
      </Button>
    </>
  ) : (
    <label>Não pediu!</label>
  )}
</Td>
                  <Td style={{ whiteSpace: 'nowrap' }} px={1} py={1}>

                    <Button
                      onClick={() => handleEditButtonClick(user)}
                      leftIcon={<FaIdCard size={30} />}
                      colorScheme="blue"
                      size="md"
                      variant="ghost"
                      fontSize="small"
                      px={0}
                      py={0}
                    />

                    <Button
                      onClick={() => handleDocumentModalOpen(user)}
                      leftIcon={<FaFileDownload size={30} />}
                      colorScheme="blue"
                      size="md"
                      variant="ghost"
                      fontSize="small"
                      px={0}
                      py={0}
                    />
                    {relacionamentoLength > 0 ? (
                      <Button style={{ alignItems: 'center' }}
                        leftIcon={<FaFlag size={14} style={{ color: "red" }} />}
                        size="md"
                        variant="ghost"
                        fontSize="small"
                        px={0}
                        py={0}
                      >
                        {relacionamentoLength}
                      </Button>
                    ) : ''}
                  </Td>
                </Tr>


              );
            })}
          </Tbody>
        </Table>
        <div>
          <button onClick={previousPage} disabled={currentPage === 1} style={{ padding: '3px' }}>
            Anterior
          </button>

          {Array.from({ length: totalPages }).map((_, index) => {

            if (
              index >= currentPage - 5 &&
              index <= currentPage + 5 &&
              index >= 0 &&
              index < totalPages
            ) {
              return (
                <button
                  key={index + 1}
                  onClick={() => changePage(index + 1)}
                  disabled={currentPage === index + 1}
                  style={currentPage === index + 1 ? { padding: '3px', fontWeight: 'bold', textDecoration: 'underline' } : { padding: '3px' }}
                >
                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                </button>
              );
            }
            return null;
          })}

          <button onClick={nextPage} disabled={currentPage === totalPages} style={{ padding: '3px' }}>
            Próximo
          </button>
        </div>



        <Modal isOpen={isAnalysisModalOpen} onClose={() => setIsAnalysisModalOpen(false)} size="3xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Análise: {selectedUser?.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowY="auto" maxH="70vh">
              <Box mb={4}>
                <label>Software Descriptor:</label>
                <SimpleGrid columns={4}>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <InputLeftAddon children="DB *" />
                      <Input
                        value={selectedUserDescriptor}
                        onChange={(e) => setSelectedUserDescriptor(e.target.value)}
                      />
                    </InputGroup>
                  </GridItem>
                  {selectedUserDescriptor !== selectedUser?.seller?.descriptor && (
                    <GridItem colSpan={1}>
                      <Button
                        onClick={handleDescriptorUpdate}
                        colorScheme="purple"
                        size="md"
                        borderRadius={"3px"}
                        width="100%">
                        Salvar
                      </Button>
                    </GridItem>
                  )}
                </SimpleGrid>
              </Box>
              <Box mb={4}>
                <label>Selecione o provedor:</label>
              </Box>
              <Box mb={4} flexDirection={"row"}>
                <SimpleGrid columns={4}>
                  <GridItem colSpan={3}>
                    <Select onChange={handleChangeProviderKey}>
                      <option value=''>Selecionar provedor</option>
                      {Object.values(ProviderKey).map((providerKey) => (
                        <option value={providerKey}>{
                          providerKey.charAt(0).toUpperCase() + providerKey.slice(1)
                        }</option>
                      ))}
                    </Select>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Button
                      onClick={handleAnalysisSubmit}
                      colorScheme="purple"
                      size="md"
                      borderRadius={"3px"}>
                      Enviar para Analise
                    </Button>
                  </GridItem>

                </SimpleGrid>
              </Box>

              <Box mb={4}>
                <label>Status do provedor:</label> <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                  }}
                >{selectedUser?.stone_status}</div>
              </Box>
              <Box mb={4}>
                <LoadingPage isOpen={isLoadingProviderAccounts} />
                <ProviderAccountBox
                  userId={selectedUser?.id}
                  refetch={refetch}
                  providerAccounts={providerAccounts}
                  sellerPaymentTypes={sellerPaymentTypes}
                  refetchSellerPaymentTypes={refetchSellerPaymentTypes}
                />
              </Box>

              <Box mb={4}>
                <label>Após ativar uma conta de provedor, clique em:</label>
                <Button
                  onClick={handleApprove}
                  colorScheme="blue"
                  size="sm"
                  borderRadius={"3px"}
                  ml="5px"
                  variant="outline" isDisabled={selectedUser?.stone_recipe_id != '' ? false : true}>
                  Aprovar
                </Button>
                <Button onClick={handleReject}
                  borderRadius={"3px"}
                  ml="5px"
                  colorScheme="red" size="sm" variant="outline">
                  Rejeitar
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isCentered id="modal" size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cadastro {selectedUser?.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowY="auto" maxH="90vh" fontSize="small">
              <table width={"100%"}>
                <tbody>
                  <tr style={{ verticalAlign: 'top' }}>
                    <td width={"60%"}>
                      <SimpleGrid columns={4} spacing={4}>
                        <Box mb={4}>
                          <label>Nome:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].name !== selectedUser?.name ? `${selectedUser?.antigo[0].name}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].name === selectedUser?.name}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.name}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Email:</label>
                          <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.email}</LabelCheckbox>
                        </Box>
                        <Box mb={4}>
                          <label>CPF:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cpf !== selectedUser?.cpf ? `${selectedUser?.antigo[0].cpf}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cpf === selectedUser?.cpf}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{cpf(selectedUser?.cpf)}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Data de Nascimento:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].birth_date !== selectedUser?.birth_date ? `${selectedUser?.antigo[0].birth_date}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].birth_date === selectedUser?.birth_date}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{datetime(selectedUser?.birth_date)}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>CEP:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].zipcode !== selectedUser?.zipcode ? `${selectedUser?.antigo[0].zipcode}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].zipcode === selectedUser?.zipcode}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{zipcode_alter(selectedUser?.zipcode)}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Endereço:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].address !== selectedUser?.address ? `${selectedUser?.antigo[0].address}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].address === selectedUser?.address}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.address}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Cidade:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].city !== selectedUser?.city ? `${selectedUser?.antigo[0].city}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].city === selectedUser?.city}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.city}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Estado:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].state !== selectedUser?.state ? `${selectedUser?.antigo[0].state}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].state === selectedUser?.state}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.state}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Complemento:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].complement !== selectedUser?.complement ? `${selectedUser?.antigo[0].complement}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].complement === selectedUser?.complement}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.complement}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Número:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].number !== selectedUser?.number ? `${selectedUser?.antigo[0].number}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].number === selectedUser?.number}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.number}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                      </SimpleGrid>
                      <hr></hr>
                      <SimpleGrid columns={2} spacing={4}>
                        <Box mb={4}>
                          <label>Faturamento:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].invoicing !== selectedUser?.invoicing ? `${selectedUser?.antigo[0].invoicing}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].invoicing === selectedUser?.invoicing}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.invoicing}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Telefone:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cellphone !== selectedUser?.cellphone ? `${selectedUser?.antigo[0].cellphone}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cellphone === selectedUser?.cellphone}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{fone(selectedUser?.cellphone)}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                      </SimpleGrid>
                      <hr></hr>
                      <SimpleGrid columns={4} spacing={4}>
                        <Box mb={4}>
                          <label>Banco ID:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].banco_id !== selectedUser?.banco_id ? `${selectedUser?.antigo[0].banco_id}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].banco_id === selectedUser?.banco_id}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.banco_id + ' ' + banco(selectedUser?.banco_id)}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Agência:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].agencia !== selectedUser?.agencia ? `${selectedUser?.antigo[0].agencia}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].agencia === selectedUser?.agencia}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.agencia}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Conta:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].conta !== selectedUser?.conta ? `${selectedUser?.antigo[0].conta}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].conta === selectedUser?.conta}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.conta}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Número PIX:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].n_pix !== selectedUser?.n_pix ? `${selectedUser?.antigo[0].n_pix}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].n_pix === selectedUser?.n_pix}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.n_pix}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                      </SimpleGrid>
                      <hr></hr>
                      <SimpleGrid columns={4} spacing={4}>
                        <Box mb={4}>
                          <label>CNPJ:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cnpj !== selectedUser?.cnpj ? `${selectedUser?.antigo[0].cnpj}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cnpj === selectedUser?.cnpj}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.cnpj}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>CNPJ Razão social:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cnpj_razao !== selectedUser?.cnpj_razao ? `${selectedUser?.antigo[0].cnpj_razao}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cnpj_razao === selectedUser?.cnpj_razao}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.cnpj_razao}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>Nome Fantasia:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cnpj_social !== selectedUser?.cnpj_social ? `${selectedUser?.antigo[0].cnpj_social}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cnpj_social === selectedUser?.cnpj_social}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.cnpj_social}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>CNPJ CEP:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cnpj_zipcode !== selectedUser?.cnpj_zipcode ? `${selectedUser?.antigo[0].cnpj_zipcode}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cnpj_zipcode === selectedUser?.cnpj_zipcode}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{zipcode_alter(selectedUser?.cnpj_zipcode)}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>CNPJ Endereço:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cnpj_address !== selectedUser?.cnpj_address ? `${selectedUser?.antigo[0].cnpj_address}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cnpj_address === selectedUser?.cnpj_address}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.cnpj_address}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>CNPJ Cidade:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cnpj_city !== selectedUser?.cnpj_city ? `${selectedUser?.antigo[0].cnpj_city}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cnpj_city === selectedUser?.cnpj_city}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.cnpj_city}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>CNPJ Estado:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cnpj_state !== selectedUser?.cnpj_state ? `${selectedUser?.antigo[0].cnpj_state}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cnpj_state === selectedUser?.cnpj_state}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.cnpj_state}</LabelCheckbox>
                          </Tooltip>
                        </Box>
                        <Box mb={4}>
                          <label>CNPJ Complemento:</label>
                          <Tooltip
                            label={antigoCheckboxCondition && selectedUser?.antigo[0].cnpj_complement !== selectedUser?.cnpj_complement ? `${selectedUser?.antigo[0].cnpj_complement}` : ""}
                            isDisabled={!selectedUser?.antigo || !selectedUser?.antigo[0] || selectedUser?.antigo[0].cnpj_complement === selectedUser?.cnpj_complement}
                          >
                            <LabelCheckbox selectedUser={selectedUser}>{selectedUser?.cnpj_complement}</LabelCheckbox>
                          </Tooltip>
                        </Box>

                      </SimpleGrid>
                      <hr></hr>

                      <Box mb={0}>
                      <label>
                        Taxa de transação atual: <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{currentNegotiatedTaxRate.toString().replace(".", ",")}%</span>
                      </label>
                    </Box>
                    <Box mb={2}>
                  <InputGroup>
                    <Input
                      type="number"
                      placeholder="Nova taxa"
                      value={negotiatedTaxRate}
                      onChange={(e) => setNegotiatedTaxRate(e.target.value)}
                      maxWidth="100px"
                      paddingRight="0rem" // Espaço para o botão na direita do input
                    />
                    <InputRightAddon width="3rem">%</InputRightAddon>
                    <Button
                      onClick={handleNegotiatedTaxUpdate}
                      colorScheme="purple"
                      size="sm"
                      borderRadius="20px"
                      marginLeft="0.5rem" // Ajuste fino para aproximar o botão do input
                    >
                      Alterar taxa de transação
                    </Button>
                  </InputGroup>
                </Box>

                <Box mb={0}>
                      <label>
                        Taxa de garantia atual: <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{currentWarrantyNegotiatedTaxRate.toString().replace(".", ",")}%</span>
                      </label>
                    </Box>
                    <Box mb={2}>
                  <InputGroup>
                    <Input
                      type="number"
                      placeholder="Nova taxa"
                      value={negotiatedWarrantyTaxRate}
                      onChange={(e) => setNegotiatedWarrantyTaxRate(e.target.value)}
                      maxWidth="100px"
                      paddingRight="0rem" // Espaço para o botão na direita do input
                    />
                    <InputRightAddon width="3rem">%</InputRightAddon>
                    <Button
                      onClick={handleNegotiatedWarrantyTaxUpdate}
                      colorScheme="orange"
                      size="sm"
                      borderRadius="20px"
                      marginLeft="0.5rem" // Ajuste fino para aproximar o botão do input
                    >
                      Alterar taxa de garantia
                    </Button>
                  </InputGroup>
                </Box>

                    </td>
                  
                    <td width={"40%"}>
                      <Box display="flex" mb={4}>
                        <Box flex="1">
                          <ul>
                            {checkboxItems.slice(0, Math.ceil(checkboxItems.length / 3)).map((item) => (
                              <li key={item}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedItems.includes(item)}
                                    onChange={() => handleItemChange(item)}
                                  />
                                  {' ' + item}
                                </label>
                              </li>
                            ))}
                          </ul>
                        </Box>
                        <Box flex="1">
                          <ul>
                            {checkboxItems
                              .slice(
                                Math.ceil(checkboxItems.length / 3),
                                Math.ceil((checkboxItems.length / 3) * 2)
                              )
                              .map((item) => (
                                <li key={item}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.includes(item)}
                                      onChange={() => handleItemChange(item)}
                                    />
                                    {' ' + item}
                                  </label>
                                </li>
                              ))}
                          </ul>
                        </Box>
                        <Box flex="1">
                          <ul>
                            {checkboxItems.slice(Math.ceil((checkboxItems.length / 3) * 2)).map((item) => (
                              <li key={item}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedItems.includes(item)}
                                    onChange={() => handleItemChange(item)}
                                  />
                                  {' ' + item}
                                </label>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </Box>
                      <Box mb={4}>
                        <label>Outras Solicitações:</label>
                        <textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          rows={3}
                          style={{ width: '100%', resize: 'vertical', border: '1px solid lightgray' }}
                        />
                      </Box>
                      <Box mb={4}>
                        <Button onClick={handleDocs} colorScheme="blue" size="sm" variant="outline">
                          Solicitar Esses Documentos
                        </Button>
                      </Box>
                      <Box overflowY="auto" maxH="200px">
                        <label>Documentos Pedidos:</label>
                        <table width={"100%"}>
                          <thead>
                            <tr>
                              <th>Data</th>
                              <th>Conteúdo</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              Object.entries(relacionamento ?? {}).map(([indice, { data, descricao }]) => (
                                <tr key={indice}>
                                  <td>{data + ""}</td>
                                  <td>{descricao + ""}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Box>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={isDocumentModalOpen} onClose={() => setIsDocumentModalOpen(false)} size="6xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Documentos {selectedUser?.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowY="auto" maxH="70vh">

              <Box mb={4}>
                <label>Documento Pessoal (Frente):</label>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                  }}
                >     {selectedUser?.foto_doc_pessoal_frente ? (
                  <a href={selectedUser?.foto_doc_pessoal_frente} target="_blank" rel="noopener noreferrer">
                    Novo: {selectedUser?.foto_doc_pessoal_frente}
                    <img src={selectedUser?.foto_doc_pessoal_frente}
                      style={{ width: 'auto', maxHeight: '300px' }}
                    />
                  </a>
                ) : 'Não encontrado'}
                </div>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                    display: antigoCheckboxCondition && selectedUser?.antigo[0].foto_doc_pessoal_frente !== selectedUser?.foto_doc_pessoal_frente ? 'block' : 'none'
                  }}

                >
                  {antigoCheckboxCondition && selectedUser?.antigo[0].foto_doc_pessoal_frente ? (
                    <a href={selectedUser?.antigo[0].foto_doc_pessoal_frente} target="_blank" rel="noopener noreferrer">
                      Antigo: {selectedUser?.antigo[0].foto_doc_pessoal_frente}
                      <img src={selectedUser?.antigo[0].foto_doc_pessoal_frente}
                        style={{ width: 'auto', maxHeight: '300px' }}
                      />
                    </a>
                  ) : ''}
                </div>
              </Box>
              <Box mb={4}>
                <label>Documento Pessoal (Verso):</label>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                  }}
                >      {selectedUser?.foto_doc_pessoal_verso ? (
                  <a href={selectedUser?.foto_doc_pessoal_verso} target="_blank" rel="noopener noreferrer">
                    Novo: {selectedUser?.foto_doc_pessoal_verso}
                    <img src={selectedUser?.foto_doc_pessoal_verso}
                      style={{ width: 'auto', maxHeight: '300px' }}
                    />
                  </a>
                ) : ''}
                </div>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                    display: selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].foto_doc_pessoal_verso !== selectedUser?.foto_doc_pessoal_verso ? 'block' : 'none'
                  }}

                >
                  {selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].foto_doc_pessoal_verso ? (
                    <a href={selectedUser?.antigo[0].foto_doc_pessoal_verso} target="_blank" rel="noopener noreferrer">
                      Antigo: {selectedUser?.antigo[0].foto_doc_pessoal_verso}
                      <img src={selectedUser?.antigo[0].foto_doc_pessoal_verso}
                        style={{ width: 'auto', maxHeight: '300px' }}
                      />
                    </a>
                  ) : ''}
                </div>
              </Box>
              <Box mb={4}>
                <label>Rosto (Frente):</label>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                  }}
                >
                  {selectedUser?.foto_rosto_frente ? (
                    <a href={selectedUser?.foto_rosto_frente} target="_blank" rel="noopener noreferrer">
                      Novo: {selectedUser?.foto_rosto_frente}
                      <img src={selectedUser?.foto_rosto_frente}
                        style={{ width: 'auto', maxHeight: '300px' }}
                      />
                    </a>
                  ) : 'Não encontrado'}
                </div>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                    display: selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].foto_rosto_frente !== selectedUser?.foto_rosto_frente ? 'block' : 'none'
                  }}

                >
                  {selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].foto_rosto_frente ? (
                    <a href={selectedUser?.antigo[0].foto_rosto_frente} target="_blank" rel="noopener noreferrer">
                      Antigo: {selectedUser?.antigo[0].foto_rosto_frente}
                      <img src={selectedUser?.antigo[0].foto_rosto_frente}
                        style={{ width: 'auto', maxHeight: '300px' }}
                      />
                    </a>
                  ) : ''}
                </div>
              </Box>
              <Box mb={4}>
                <label>Comprovante Endereço:</label>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                  }}
                >
                  {selectedUser?.comprovante_endereco ? (
                    <a href={selectedUser?.comprovante_endereco} target="_blank" rel="noopener noreferrer">
                      Novo: {selectedUser?.comprovante_endereco}
                      <iframe src={selectedUser?.comprovante_endereco}
                        style={{ width: 'auto', maxHeight: '300px' }}
                      />
                    </a>
                  ) : ''}
                </div>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                    display: selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].comprovante_endereco !== selectedUser?.comprovante_endereco ? 'block' : 'none'
                  }}

                >
                  {selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].comprovante_endereco ? (
                    <a href={selectedUser?.antigo[0].comprovante_endereco} target="_blank" rel="noopener noreferrer">
                      Antigo: {selectedUser?.antigo[0].comprovante_endereco}
                      <iframe src={selectedUser?.antigo[0].comprovante_endereco}
                        style={{ width: 'auto', maxHeight: '300px' }}
                      />
                    </a>
                  ) : ''}
                </div>
              </Box>
              <hr></hr>
              <Box mb={4}>
                <label>Documento CNPJ:</label>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                  }}
                >
                  {selectedUser?.cnpj_doc_mei ? (
                    <>
                      <a href={selectedUser?.cnpj_doc_mei} target="_blank" rel="noopener noreferrer">
                        Novo: {selectedUser?.cnpj_doc_mei}
                      </a>
                      <iframe src={selectedUser?.cnpj_doc_mei} style={{ width: 'auto', maxHeight: '600px' }}></iframe>
                    </>
                  ) : ''}
                </div>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                    display: selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].cnpj_doc_mei !== selectedUser?.cnpj_doc_mei ? 'block' : 'none'
                  }}

                >
                  {selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].cnpj_doc_mei ? (
                    <a href={selectedUser?.antigo[0].cnpj_doc_mei} target="_blank" rel="noopener noreferrer">
                      Antigo: {selectedUser?.antigo[0].cnpj_doc_mei}
                      <iframe src={selectedUser?.antigo[0].cnpj_doc_mei} style={{ width: 'auto', maxHeight: '600px' }}></iframe>
                    </a>
                  ) : ''}
                </div>
              </Box>
              <Box mb={4}>
                <label>Contrato Social:</label>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                  }}
                >
                  {selectedUser?.cnpj_certidao ? (
                    <>
                      <a href={selectedUser?.cnpj_certidao} target="_blank" rel="noopener noreferrer">
                        Novo: {selectedUser?.cnpj_certidao}
                      </a>
                      <iframe src={selectedUser?.cnpj_certidao} style={{ width: 'auto', maxHeight: '600px' }}></iframe>
                    </>
                  ) : ''}
                </div>
                <div
                  style={{
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                    paddingLeft: '4px',
                    paddingBottom: '2px',
                    fontWeight: 'bold',
                    minHeight: '20px',
                    display: selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].cnpj_certidao !== selectedUser?.cnpj_certidao ? 'block' : 'none'
                  }}

                >
                  {selectedUser?.antigo && selectedUser?.antigo[0] && selectedUser?.antigo[0].cnpj_certidao ? (
                    <a href={selectedUser?.antigo[0].cnpj_certidao} target="_blank" rel="noopener noreferrer">
                      Antigo: {selectedUser?.antigo[0].cnpj_certidao}

                      <iframe src={selectedUser?.antigo[0].cnpj_certidao} style={{ width: 'auto', maxHeight: '600px' }}></iframe>
                    </a>
                  ) : ''}
                </div>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}


