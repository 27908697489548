import React, { ComponentType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuth } from "hooks/authHook";

// Define a interface para as propriedades do PrivateRoute
interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/sign-in" />
        )
      }
    />
  );
};

export default PrivateRoute;
