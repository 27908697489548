import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ContextProvider } from "hooks/contextApi";
import { MembershipContextProvider } from "hooks/membershipContext";
import { PaymentRequestContextProvider } from "hooks/paymentRequestContext";
import { AuthProvider } from "hooks/authHook";
import { notifyError } from 'utils/notification';
import PrivateRoute from "./privateroute";



const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      console.log('error', error);
      notifyError('Erro ao buscar dados, recarregue a página!');
    }
  })
});

ReactDOM.render(
  <ContextProvider>
    <AuthProvider>
      <PaymentRequestContextProvider>
        <MembershipContextProvider>
          <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <React.StrictMode>
                <HashRouter>
                  <Switch>
                    <Route path={`/auth`} component={AuthLayout} />
                    {/* Substitua as rotas sensíveis por PrivateRoute */}
                    <PrivateRoute path={`/admin`} component={AdminLayout} />
                    <PrivateRoute path={`/payment-plan`} component={AdminLayout} />
                    <Redirect exact from="/" to="/auth" />
                  </Switch>
                </HashRouter>
              </React.StrictMode>
            </QueryClientProvider>
          </ChakraProvider>
        </MembershipContextProvider>
      </PaymentRequestContextProvider>
    </AuthProvider>
  </ContextProvider>,
  document.getElementById("root")
);
