import axios from "./api";
import {GetlastOrders, GetOrdersSum, GetViews, GetHighlightProduct, GetOrdersPerDay, GetAllStores} from "models/Metrics";
import { useUserStore } from "store/reducers/user"
import { adminApi } from "./AdminApi/api";
import { OrderResponse } from "models/Order";


export const getOrderMetricsAdmin = async (params: GetlastOrders.Params): Promise<GetlastOrders.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/api/v1/metrics/dashboard/admin_orders`;
  try {

    const orders = await adminApi.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        params: {
          daysAgo: params.daysAgo,
          storeId: params.storeId
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return orders.data
  } catch (err) {
    console.log(err);
  }
};

export const getOrderSumMetricsAdmin = async (params: GetOrdersSum.Params): Promise<GetOrdersSum.Result> => {
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/api/v1/metrics/dashboard/admin_orders/sum`;
  
  try {
    const queryParams = {
      storeId: params.storeId,
      ...(params.startDate && params.endDate ? { startDate: params.startDate, endDate: params.endDate } : { daysAgo: params.daysAgo })
    };

    const orders = await adminApi.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        params: queryParams,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return orders.data;
  } catch (err) {
    console.log(err);
  }
};


export const getProductMetricsAdmin = async (params: GetHighlightProduct.Params): Promise<GetHighlightProduct.Result> => {
    const token = useUserStore.getState().auth.token;
    const adminUrl = `/api/v1/metrics/dashboard/admin_products/highlights`;
    try {
  
      const orders = await adminApi.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`,
        
        {
          params: {
            daysAgo: params.daysAgo,
            storeId: params.storeId
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      return orders.data;
    } catch (err) {
      console.log(err);
    }
};

export const getViewMetricsAdmin = async (params: GetViews.Params): Promise<GetViews.Result> => {
    const token = useUserStore.getState().auth.token;
    const adminUrl = `/api/v1/metrics/dashboard/admin_orders/views`;
    try {
  
      const orders = await adminApi.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`,
        {
          params: {
            daysAgo: params.daysAgo,
            storeId: params.storeId
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      return orders.data
    } catch (err) {
      console.log(err);
    }
};

export const getOrdersPerDayAdmin = async (params: GetOrdersPerDay.Params): Promise<GetOrdersPerDay.Result> => {
    const token = useUserStore.getState().auth.token;
    const adminUrl = `/api/v1/metrics/dashboard/admin_orders/per-day`;
    try {
  
      const orders = await adminApi.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`,
        {
          params: {
            daysAgo: params.daysAgo,
            storeId: params.storeId
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      return orders.data
    } catch (err) {
      console.log(err);
    }
};

export const getAllStoresAdmin = async (): Promise<GetAllStores.Result> => {
  // console.log('6')
  const token = useUserStore.getState().auth.token;
  const adminUrl = `/api/v1/metrics/dashboard/admin_sellers`;
  try {

    const orders = await adminApi.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return orders.data
  } catch (err) {
    console.log(err);
  }
};