import React, { useEffect, useState } from 'react';
import { Box, GridItem, SimpleGrid, Flex, Spinner, Input, Button, useColorModeValue, Text, Stack } from '@chakra-ui/react';
import { CustomGridItem, HeaderContent, HeaderItem } from "../styles";
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdRequestPage, MdApproval, MdCancel, MdError } from 'react-icons/md';
import { getOrderSumMetricsAdmin, getOrdersPerDayAdmin, getOrderMetricsAdmin, getAllStoresAdmin } from "services/metricsRequest";
import { notifyError } from 'utils/notification';
import { formatCurrency } from 'helper/currency';
import PaymentMethodsGraph from '../components/PaymentMethodsGraph';
import OrdersPerDayGraph from '../components/OrdersPerDayGraph';
import ListOrders from '../components/ListOrders/ListOrders';
import { CustomSelect } from 'styleguide/Select';

const formatDateForInput = (date: Date) => {
    return date.toISOString().split('T')[0]; // Formato 'YYYY-MM-DD'
};

const getDateXDaysAgo = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
};

export default function DashboardTable() {
    const [loading, setLoading] = useState(true);
    const [storeArray, setStoreArray] = useState([]);
    const [totalOrders, setTotalOrders] = useState("");
    const [totalPaid, setTotalPaid] = useState("");
    const [totalCancelled, setTotalCancelled] = useState("");
    const [totalPending, setTotalPending] = useState("");
    const [totalChargeback, setTotalChargeback] = React.useState("");
    const [orderSum, setOrderSum] = useState({});
    const [ordersPerDay, setOrdersPerDay] = useState([]);
    const [lastOrders, setLastOrders] = useState([]);
    const [daysAgo, setDaysAgo] = useState(7); // Valor padrão de 7 dias
    const [storeId, setStoreId] = useState('0');
    const [filterType, setFilterType] = useState<'daysAgo' | 'dateRange'>('daysAgo'); // Tipo de filtro
    const [startDate, setStartDate] = useState(formatDateForInput(getDateXDaysAgo(7))); // Início do intervalo
    const [endDate, setEndDate] = useState(formatDateForInput(new Date())); // Fim do intervalo

    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const activeButtonBg = useColorModeValue('purple.300', 'purple.500'); // Tom de roxo claro para o botão ativo
    const inactiveButtonBg = useColorModeValue('gray.300', 'gray.500');

    useEffect(() => {
        fetchData();
    }, [storeId, daysAgo, startDate, endDate, filterType]);

    const handleGetTransactions = async (storeId: string) => {
        const params = filterType === 'daysAgo'
            ? { daysAgo, storeId }
            : { startDate, endDate, storeId };

        const [ordersSumMetrics, ordersPerDayResult, lastOrdersResult] = await Promise.all([
            getOrderSumMetricsAdmin(params),
            getOrdersPerDayAdmin(params),
            getOrderMetricsAdmin(params),
        ]);

        if (ordersSumMetrics.success) {
            const data = ordersSumMetrics.data;

            const completedTotal = Number(data.order_results.completed_total || 0);
            const pendingTotal = Number(data.order_results.pending_total || 0);
            const chargebackTotal = Number(data.order_results.chargeback_total || 0);
            const totalOrders = completedTotal + pendingTotal + chargebackTotal;

            setTotalOrders(formatCurrency(totalOrders));
            setTotalPaid(formatCurrency(completedTotal));
            setTotalCancelled(formatCurrency(parseFloat(data.order_results.cancelled_total) || 0));
            setTotalPending(formatCurrency(pendingTotal));
            setTotalChargeback(formatCurrency(chargebackTotal));
            setOrderSum(data);
        }

        if (ordersPerDayResult.success) {
            setOrdersPerDay(ordersPerDayResult.data);
        }

        if (lastOrdersResult.success) {
            setLastOrders(lastOrdersResult.data);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const stores = await getAllStoresAdmin();

            if (stores.success) {
                setStoreArray(stores.data);
            }
            setLoading(false);
            handleGetTransactions(storeId);
        } catch (error) {
            notifyError("Ops! Tivemos um problema ao buscar as métricas.");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(event.target.value, 10);
        setDaysAgo(selectedValue);
        setStartDate(formatDateForInput(getDateXDaysAgo(selectedValue)));
        setEndDate(formatDateForInput(new Date()));
    };

    const handleInputStoreChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setStoreId(selectedValue);
    };

    const toggleFilterType = (type: 'daysAgo' | 'dateRange') => {
        if (type === 'dateRange') {
            setStartDate(formatDateForInput(getDateXDaysAgo(daysAgo)));
            setEndDate(formatDateForInput(new Date()));
        }
        setFilterType(type);
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, setDate: React.Dispatch<React.SetStateAction<string>>) => {
        setDate(event.target.value);
    };

    return (
        <Box>
            {loading ? (
                <Flex direction={"column"}>
                    <Spinner />
                </Flex>
            ) : (
                <React.Fragment>
                    <SimpleGrid columns={{ base: 2, md: 2, lg: 2, '2xl': 4 }} gap='20px' mt='40px'>
                        <GridItem colSpan={{ base: 2, md: 2, lg: 2, '2xl': 4 }}>
                            <HeaderContent>
                                <HeaderItem>
                                    <Text as="h2" fontWeight="bold" fontSize="2xl" color="black">
                                        Pedidos
                                    </Text>
                                </HeaderItem>
                                <Flex direction={{ md: 'row', base: 'column' }} alignItems="center">
                                    <Box mr={{ md: '10px', base: '0' }} mb={{ md: '0', base: '5px' }}>
                                        <CustomSelect
                                            name="store_id"
                                            onChange={handleInputStoreChange}
                                            required
                                            value={storeId}
                                        >
                                            <option value="0">Todas as lojas</option>
                                            {storeArray.map((value, index) => (
                                                <option key={index} value={value.id}>
                                                    {value.storename}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </Box>
                                    <Box>
                                        {filterType === 'daysAgo' ? (
                                            <CustomSelect
                                                name="daysAgo"
                                                value={daysAgo}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="0">Hoje</option>
                                                <option value="1">Ontem</option>
                                                <option value="7">Últimos 7 dias</option>
                                                <option value="15">Últimos 15 dias</option>
                                                <option value="30">Últimos 30 dias</option>
                                                <option value="365">Último ano</option>
                                            </CustomSelect>
                                        ) : (
                                            <Flex>
                                                <Box mr="5px">
                                                    <Text fontSize="sm" mb="2px">De</Text>
                                                    <Input
                                                        type="date"
                                                        value={startDate}
                                                        onChange={(event) => handleDateChange(event, setStartDate)}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Text fontSize="sm" mb="2px">Até</Text>
                                                    <Input
                                                        type="date"
                                                        value={endDate}
                                                        onChange={(event) => handleDateChange(event, setEndDate)}
                                                    />
                                                </Box>
                                            </Flex>
                                        )}
                                    </Box>
                                    <Stack direction="column" ml="10px" spacing="5px">
                                        <Button
                                            onClick={() => toggleFilterType('daysAgo')}
                                            bg={filterType === 'daysAgo' ? activeButtonBg : inactiveButtonBg}
                                            color="white"
                                            _hover={{ bg: filterType === 'daysAgo' ? useColorModeValue('purple.400', 'purple.600') : inactiveButtonBg }}
                                            _active={{ bg: filterType === 'daysAgo' ? useColorModeValue('purple.500', 'purple.700') : inactiveButtonBg }}
                                        >
                                            Últimos dias
                                        </Button>
                                        <Button
                                            onClick={() => toggleFilterType('dateRange')}
                                            bg={filterType === 'dateRange' ? activeButtonBg : inactiveButtonBg}
                                            color="white"
                                            _hover={{ bg: filterType === 'dateRange' ? useColorModeValue('purple.400', 'purple.600') : inactiveButtonBg }}
                                            _active={{ bg: filterType === 'dateRange' ? useColorModeValue('purple.500', 'purple.700') : inactiveButtonBg }}
                                        >
                                            Intervalo entre datas
                                        </Button>
                                    </Stack>
                                </Flex>
                            </HeaderContent>
                        </GridItem>
                        <CustomGridItem columns={{ base: 1 }}>
                            <MiniStatistics
                                startContent={
                                    <IconBox
                                        w='56px'
                                        h='56px'
                                        bg={boxBg}
                                        icon={<MdRequestPage size="32px" color="blue" />}
                                    />
                                }
                                name='Pedidos Realizados'
                                value={totalOrders || 0}
                            />
                        </CustomGridItem>
                        <CustomGridItem columns={{ base: 1 }}>
                            <MiniStatistics
                                startContent={
                                    <IconBox
                                        w='56px'
                                        h='56px'
                                        bg={boxBg}
                                        icon={<MdApproval size="32px" color="green" />}
                                    />
                                }
                                name='Pedidos Aprovados'
                                value={totalPaid || 0}
                            />
                        </CustomGridItem>
						<CustomGridItem columns={{ base: 1 }}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<MdCancel size="32px" color="red" />}
									/>
								}
								name='Pedidos Cancelados'
								value={totalCancelled || 0}
							/>
						</CustomGridItem>
						<CustomGridItem columns={{ base: 1 }}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<MdRequestPage size="32px" color="orange" />} // Ícone para Pedidos Pendentes
									/>
								}
								name='Pedidos pendentes'
								value={totalPending || 0}
							/>
						</CustomGridItem>
						<CustomGridItem columns={{ base: 1 }}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<MdError size="32px" color="purple" />} // Ícone para Chargeback
									/>
								}
								name='Chargeback'
								value={totalChargeback || 0}
							/>
						</CustomGridItem>

                        <CustomGridItem columns={{ base: 2 }}	>
                            <OrdersPerDayGraph orders={ordersPerDay} />
                        </CustomGridItem>

                        <CustomGridItem columns={{ base: 2 }}>
                            <PaymentMethodsGraph methods={orderSum} />
                        </CustomGridItem>
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 2, md: 2, lg: 2, '2xl': 4 }} gap='20px' mt='20px'>
                        <CustomGridItem columns={{ base: 2 }}>
                            <ListOrders orders={lastOrders} />
                        </CustomGridItem>
                    </SimpleGrid>
                </React.Fragment>
            )}
        </Box>
    );
}
